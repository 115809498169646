
import { Action, Component, Vue } from 'nuxt-property-decorator'
import AboutInbox from '~/components/get-start/about-inbox.vue'

@Component({
  components: { AboutInbox },
  layout: 'none',
})
export default class extends Vue {
  @Action
  private readonly setRedirect!: (path: string) => void

  private created (): void {
    if (typeof this.$route.query.to === 'string') {
      this.setRedirect(this.$route.query.to)
    }
  }
}
