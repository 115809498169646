
import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { Carousel } from 'types/carousel'

@Component
export default class extends Vue {
  private sliderIndex = 1

  @Prop({ default: [] })
  private carousels!: Carousel[]

  get disableControlRightButton (): boolean {
    return this.sliderIndex >= this.carousels.length
  }

  get disableControlLeftButton (): boolean {
    return this.sliderIndex <= 1
  }

  get sliderItemWidth (): string {
    if (this.carousels.length < 1) {
      return '100%'
    }

    return `${100 / this.carousels.length}%`
  }

  prev (): void {
    if (this.sliderIndex <= 1) {
      this.sliderIndex = this.carousels.length
      return
    }
    this.sliderIndex -= 1
  }

  next (): void {
    if (this.sliderIndex >= this.carousels.length) {
      this.sliderIndex = 1
      return
    }
    this.sliderIndex += 1
  }

  reset (): void {
    this.sliderIndex = 1
  }

  activeSliderStyles (index: number): unknown {
    const carouselInnerWidth = `${this.carousels.length * 100}%`
    if (this.sliderIndex !== index) {
      return {
        width: carouselInnerWidth,
      }
    }
    let marginLeft = 0
    if ((index - 1) > 0) {
      marginLeft = (index - 1) * -100
    }

    return {
      width: carouselInnerWidth,
      marginLeft: `${marginLeft}%`,
    }
  }
}
