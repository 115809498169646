
import { Component, Vue } from 'nuxt-property-decorator'
import Carousel from './carousel.vue'
import { Content } from '~/types/carousel'

@Component({
  components: {
    Carousel,
  },
})
export default class extends Vue {
  private contents: {[key: string]: Content} = {
    accountTransfer: {
      title: '自動引き落としに変更',
      carousels: [
        {
          pageNo: '01',
          description: '支払状況ページで「支払方法の変更」より口座振替へ切り替えたい販売元を選びます。',
          image: 'https://c.mfkessai.co.jp/img/inbox/account_transfer/1/pc/1.png',
          spImage: 'https://c.mfkessai.co.jp/img/inbox/account_transfer/1/sp/1.png',
        },
        {
          pageNo: '02',
          description: '口座振替依頼書の送付依頼をフォームから行ってください。',
          image: 'https://c.mfkessai.co.jp/img/inbox/account_transfer/1/pc/2.png',
          spImage: 'https://c.mfkessai.co.jp/img/inbox/account_transfer/1/sp/2.png',
        },
        {
          pageNo: '03',
          description: '入力完了後、貴社へ2〜3営業日以内に口座振替依頼書を発送いたします。',
          image: 'https://c.mfkessai.co.jp/img/inbox/account_transfer/1/pc/3.png',
          spImage: 'https://c.mfkessai.co.jp/img/inbox/account_transfer/1/sp/3.png',
        },
      ],
    },
    refundRequest: {
      title: 'Webで返金依頼',
      carousels: [
        {
          pageNo: '01',
          description: '支払状況ページの「支払超過」バナーを選んでください。',
          note: '※ご入金データの反映は2営業日程度かかる場合がございます。',
          image: 'https://c.mfkessai.co.jp/img/inbox/payment/2/pc/1.png',
          spImage: 'https://c.mfkessai.co.jp/img/inbox/payment/2/sp/1.png',
        },
        {
          pageNo: '02',
          description: '支払超過ページから対象の販売元の「返金依頼・口座振替依頼はコチラ」をクリックしてください。',
          note: '※ご入金データの反映は2営業日程度かかる場合がございます。',
          image: 'https://c.mfkessai.co.jp/img/inbox/payment/2/pc/2.png',
          spImage: 'https://c.mfkessai.co.jp/img/inbox/payment/2/sp/2.png',
        },
        {
          pageNo: '03',
          description: '初めて返金依頼をされる方は「返金口座の登録」を選んでください。すでに返金口座を登録済みの方は次のページをご確認ください。',
          note: '※返金口座の登録確認には約3営業日かかります。',
          image: 'https://c.mfkessai.co.jp/img/inbox/payment/2/pc/3.png',
          spImage: 'https://c.mfkessai.co.jp/img/inbox/payment/2/sp/3.png',
        },
        {
          pageNo: '04',
          description: '返金口座を登録完了後「返金依頼をする」を選んでください。',
          note: '※返金は月二回（中頃・末）実施致しております。',
          image: 'https://c.mfkessai.co.jp/img/inbox/payment/2/pc/4.png',
          spImage: 'https://c.mfkessai.co.jp/img/inbox/payment/2/sp/4.png',
        },
      ],
    },
    invoice: {
      title: '請求書をダウンロード',
      carousels: [
        {
          pageNo: '01',
          description: '請求書一覧ページより確認したい請求書を選びます。',
          note: '※ご入金データの反映は2営業日程度かかる場合がございます。',
          image: 'https://c.mfkessai.co.jp/img/inbox/billing/1/pc/1.png',
          spImage: 'https://c.mfkessai.co.jp/img/inbox/billing/1/sp/1.png',
        },
        {
          pageNo: '02',
          description: '請求書詳細画面で支払期限や支払口座の確認ができます。',
          note: '※ご入金データの反映は2営業日程度かかる場合がございます。',
          image: 'https://c.mfkessai.co.jp/img/inbox/billing/1/pc/2.png',
          spImage: 'https://c.mfkessai.co.jp/img/inbox/billing/1/sp/2.png',
        },
        {
          pageNo: '03',
          description: '請求書をダウンロードして取引内容を確認できます。',
          note: '※ご入金データの反映は2営業日程度かかる場合がございます。',
          image: 'https://c.mfkessai.co.jp/img/inbox/billing/1/pc/3.png',
          spImage: 'https://c.mfkessai.co.jp/img/inbox/billing/1/sp/3.png',
        },
      ],
    },
    payment: {
      title: '支払い状況の確認',
      carousels: [
        {
          pageNo: '01',
          description: '販売元一覧ページから、支払履歴を確認したい販売元を選びます。',
          image: 'https://c.mfkessai.co.jp/img/inbox/payment/1/pc/1.png',
          spImage: 'https://c.mfkessai.co.jp/img/inbox/payment/1/sp/1.png',
        },
        {
          pageNo: '02',
          description: '販売元詳細ページの、支払履歴一覧よりご確認ください。',
          image: 'https://c.mfkessai.co.jp/img/inbox/payment/1/pc/2.png',
          spImage: 'https://c.mfkessai.co.jp/img/inbox/payment/1/sp/2.png',
        },
      ],
    },
  }

  private onSelectContent (key: string): void {
    const target = (this.$refs.menuList as HTMLElement).querySelector(`.key-${key}`)
    if (!target) {
      return
    }
    const isSelected = !!(this.$refs.menuList as HTMLElement).querySelector('li.active')
    const listItems = (this.$refs.menuList as HTMLElement).querySelectorAll('li')
    if (isSelected) {
      // 選択中の場合は、初期状態に戻す
      listItems.forEach((item, i) => {
        if (item.classList.contains('active')) {
          item.classList.remove('active')
          setTimeout(() => {
            /* 1ページ目に戻す。消えるアニメーションが終わってから動かすため200ms遅延。 */
            if (this.$refs.carousel) {
              const carousel = this.$refs.carousel[i] as Carousel
              carousel.reset()
            }
          }, 200)
        } else {
          item.classList.remove('hide')
        }
      })
      return
    }

    this.$gtm.push({ event: 'ClickLoginPageInfo', itemKey: key })
    listItems.forEach((item) => {
      const classList = item.classList
      if (item === target) {
        classList.add('active')
      } else {
        classList.add('hide')
      }
    })
  }
}
